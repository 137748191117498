(function () {
    const menuElements = document.querySelectorAll('.menu-main')

    const navHover = (nav) => {

        const linkElements = Array.prototype.slice.call(nav.querySelectorAll('li>a'));

        let activeItem = null;
        let stateTarget;
        let stateTimeout;
        let defStyle = {
            width: null,
            left: null
        }

        const init = () => {
            appendDecor()
            findActive()

            if (activeItem) {
                defStyle = getStyle(activeItem)
                countStyle(getDecorEl(activeItem), activeItem)
            }

            document.addEventListener('mouseover', onHover)
            document.addEventListener('mouseout', onLeave)
            document.addEventListener('changeClassname', (e) => {
                findActive()
                onHover(e);
            })
        }

        const findActive = () => {
            let active;
            for (let i = 0; i < linkElements.length; i++) {

                active = linkElements[i].closest('.current-parent')
                    || linkElements[i].closest('.current-item')
                    || linkElements[i].closest('.current-menu-item')

                if (active) {
                    //console.log('get active ', active)
                    activeItem = active
                }
                // console.log(activeItem)
            }
        }

        const appendDecor = () => {
            let span = document.createElement('span')
            span.classList.add('decor')
            nav.appendChild(span)
        }

        const getDecorEl = (el) => {
            if (!el)
                return;

            return el.closest('.menu').querySelector('.decor');
        }

        const getStyle = (el) => {
            if (!el)
                return {};

            return {
                width: `${el.getBoundingClientRect().width}px`,
                left: `${el.getBoundingClientRect().left}px`
            }
        }

        const countStyle = (el, target = null) => {
            if (!el) {
                return
            }

            let style = defStyle;

            if (target) {
                style = getStyle(target)
            }

            for (const property in style) {
                el.style[property] = style[property]
            }
        }

        const removeStyle = (el) => {
            if (!el)
                return

            for (const property in defStyle) {
                el.style[property] = defStyle[property]
            }
        }

        const onHover = ({target}) => {
            if (!linkElements.includes(target.closest('a')))
                return;

            stateTarget = target
            if (stateTimeout) {
                clearTimeout(stateTimeout)
            }

            let el = getDecorEl(target)
            countStyle(el, target)

        }

        const onLeave = ({target}) => {
            if (!linkElements.includes(target.closest('a')))
                return;

            if (stateTimeout) {
                clearTimeout(stateTimeout)
            }

            stateTimeout = setTimeout(() => {
                if (target == stateTarget) {
                    console.log(activeItem)
                    if (activeItem) {
                        countStyle(getDecorEl(activeItem), activeItem)
                    } else {
                        removeStyle(getDecorEl(target))
                    }
                }
            }, 500)
        }

        init()
    }


    if (!menuElements)
        return

    for (let i = 0; i < menuElements.length; i++) {
        new navHover(menuElements[i])
    }

})()