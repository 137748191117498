(function () {
    const className = "current-item"
    const parentSelector = ".menu-item"
    const menuElement = document.querySelector('.anchor-menu')
    const linkElements = Array.prototype.slice.call(document.querySelectorAll('.anchor-menu a'));

    const classEvent = new Event("changeClassname", {bubbles: true})

    const findSections = () => {
        for (let i = 0; i < linkElements.length; i++) {
            const currentLink = linkElements[i];

            if (currentLink.hash) {
                let target = document.querySelector(currentLink.hash)
                if (target)
                    sections.push({index: i, el: target, top: 0, bottom: 0})
            }
        }
    }

    const calcOffset = () => {
        for (let i = 0; i < sections.length; i++) {
            const current = sections[i];
            const next = sections[i + 1] ?? null;
            sections[i].top = current.el.offsetTop
            sections[i].bottom = next ? next.el.offsetTop : current.el.offsetHeight * 1.5 + current.el.offsetTop
        }
    }

    const removeClassName = () => {
        for (let i = 0; i < linkElements.length; i++) {
            linkElements[i].closest(parentSelector).classList.remove(className);
        }
    }

    const addClassName = (i) => {
        linkElements[i].closest(parentSelector).classList.add(className);
        linkElements[i].dispatchEvent(classEvent)
    }

    const getCurrentMenu = (offset = 0) => {

        for (let i = 0; i < sections.length; i++) {
            const current = sections[i];
            if (current.top <= offset && current.bottom > offset) {
                if (currentIndex !== current.index) {
                    currentIndex = current.index
                    removeClassName()
                    addClassName(current.index)
                }

                return
            }
        }
    }

    let sections = [];
    let currentIndex = -1;

    if (!menuElement) {
        return
    }

    document.addEventListener("DOMContentLoaded", function () {
        findSections()
        calcOffset()
        getCurrentMenu()
    });


    document.addEventListener('scroll', (e) => {
        let scrollY = window.scrollY || window.pageYOffset
        let scrollH = window.innerHeight
        getCurrentMenu(scrollY - scrollH / 2)
    })

    document.addEventListener('resize', () => {
        calcOffset()
    })

})()