import {Fancybox, Carousel} from "@fancyapps/ui";

const carouselEL = document.querySelectorAll('.carousel')
for (let i = 0; i < carouselEL.length; i++) {
    new Carousel(carouselEL[i], {
        slidesPerPage: 1,
        fill: false,
        friction: 0.75,
        infinite: true
    })
}