import axios from "axios";

export default {
    search: {
        searchAny: (s) => axios.get('/wp-json/wp/v2/search/', {
            params: {
                search: s,
                per_page: 5
            },
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => res.data)
    },
}