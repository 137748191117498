import api from "./api";
import highlightText from "./hightlightText";
import {Collapse} from "bootstrap";

(function () {
    const toggle = document.getElementById('searchToggle')
    const collapse = document.getElementById('searchForm')
    const input = document.getElementById('searchInput')
    const list = document.getElementById('searchResults')
    let isRequest = false

    const makeRequest = (s) => {
        return api.search.searchAny(s)
    }

    const appendResults = (data, s = '') => {
        if (!list) return
        list.innerText = '';

        if (data.length) {

            for (let i = 0; i < data.length; i++) {
                let temp = document.createElement('li')
                temp.innerHTML = `<a href="${data[i].url}">${highlightText(data[i].title, s)}</a>`
                list.appendChild(temp)
            }
        }
        console.log('appanded!')
    }

    const search = ({target}) => {
        if (isRequest) return;

        if (target.value.length < 2)
            return;

        isRequest = true

        makeRequest(target.value)
            .then(data => appendResults(data, target.value))
            .finally(() => isRequest = false)
    }

    if (!toggle || !collapse || !input)
        return

    let searchCollapse = new Collapse(collapse, {
        toggle: false
    })

    document.addEventListener('click', ({target}) => {
        if (target != toggle && target != collapse) {
            return;
        }

        searchCollapse.toggle()
    })

    // document.addEventListener('change', ({target}) => {
    //     console.log(target)
    //     if (target !== input) {
    //         return;
    //     }
    //
    //     makeRequest(target.value)
    //         .then(data => appendResults(data, target.value))
    //         .finally(() => isRequest = false)
    // })
    input.addEventListener('change', search)
    input.addEventListener('keyup', search)

    document.addEventListener('submit', (event) => {
        if (event.target !== input.closest('form')) {
            return;
        }

        event.preventDefault()
    })
})()