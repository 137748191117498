import {Collapse, Dropdown, Tooltip} from "bootstrap";
import './fancyapp'

import './parallax'
import "./search"
import './anchorNav'
import './navHover'
//
// if (document.querySelector('.splide')) {
//     new Splide('.splide').mount();
// }


console.log('Hello App!')

let dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
let dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    return new Dropdown(dropdownToggleEl, {
        popperConfig: {
            placement: "bottom"
        }
    })
})

let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
})